<template>
	<div>
		<Navbar value='1' />
		<div class="root__services">
			<div class="conatainer__">
				<img src="../../assets/yicimages/register/signuppageimage.png" />
			</div>
		</div>
		<!-- footer -->
		<FooterYic />
	</div>
</template>
  
<script>
import {
	BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import Navbar from "../navbar/Navbar.vue";
import FooterYic from '../homepage/footer.vue'

export default {
	components: {
		BForm,
		BCard,
		BRow,
		BCol,
		BAvatar,
		BCardBody,
		BInputGroup,
		BFormInput,
		BCardText,
		BInputGroupPrepend,
		BTabs,
		BTab,
		BImg,
		Navbar,
		FooterYic,
	},
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style scoped>
.conatainer__ {
	/* margin-top: 2rem; */
	padding: 2rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 700px;
	height: 890px;
}

.conatainer__>img {
	width: 100%;
	height: 100%;
}

.root__services {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;

}


@media screen and (max-width: 600px) {
	.conatainer__ {

		height: max-content;
	}

	.conatainer__>img {
		width: 100%;
		height: 34rem;
	}

}
</style>
  